/* eslint-disable react/display-name */
import Link from "next/link";
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Icon } from "./Icon";
import { Button } from "./Button";
import { isProduction } from "./helpers";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { ThemeContext } from "./ContextProviders/ThemeContext";
const navigation = require("../data/navigation.json");

const ListItem = (props) => {
    return <React.Fragment>
        <li>
            <NavigationMenu.Link asChild>
                <Link href={props.href} className="ListItemLink nav-button" shallow={props.shallow}>
                    <div>
                        <div className="ListItemHeading">{props.title}</div>
                        <p className="ListItemText">{props.children}</p>
                    </div>
                </Link>
            </NavigationMenu.Link>
        </li>
        <style jsx>
            {`
        :global(a.nav-button){
          display:flex !important;
          gap:10px;
        }
        .nav-icon{
          width:36px;
          min-width:36px;
          height:auto;
          align-self:start;
        }

        li {
            padding-bottom: 0;
        }

      `}
        </style>
    </React.Fragment>;
};


const CategoryItem = (props) => {
    return <React.Fragment>
        <NavigationMenu.Link asChild>
            <li style={{ gridRow: 'span 1' }} className={`${props.isHovered === props.title && "active"} category ListItemLink nav-button`}>
                <NavigationMenu.Link asChild>
                    <div className="category-content">
                        {props.href && <Link className="Callout category-select" href={props.href} shallow>
                            <div className="CategoryItemHeading">{props.title}</div>
                            <p className="CategoryItemText">{props.children}</p>
                        </Link>}
                        {!props.href && <span><div className="CategoryItemHeading">{props.title}</div><p className="CategoryItemText">{props.children}</p></span>}
                        <div className="nav-icon">
                            <span className="material-symbols-rounded">chevron_right</span>
                        </div>           
                    </div>
                </NavigationMenu.Link>
            </li>
        </NavigationMenu.Link>
        <style jsx>
            {`
        :global(a.nav-button){
          display:flex !important;
          gap:10px;
        }
        .nav-icon{
          width:36px;
          min-width:36px;
          height:auto;
          align-self:start;
          opacity: ${props.isHovered === props.title ? "100%" : "0"};
        }
        .category{
            min-height: 5rem;
            min-width:25rem;
        }
        .category-content{
            display:flex;
            gap:2rem;
            justify-content:between;
            align-items:center;

            width:100%;
            height:100%;
        }
        :global(.category-select){
            flex-grow:1!important;
        }
        .nav-icon{
            display:flex;
            margin-top:20px;
            direction:column;
            justify-content:center;
            align-items:center;
        }
        .active {
            background-image: linear-gradient(135deg, #00d8d521 0%, #ff4c8b33 100%);
        }

      `}
        </style>
    </React.Fragment>;
};

const itemsFilter = (items) => {
    return !items.hidden && !items.footerOnly;
};


const ParentItem = ({ parent }) => {
    const [isHovered, setIsHovered] = useState("GoldRush Kit");

    const handleMouseEnter = (item: any) => {
        setIsHovered(item.title);
    };

    return <React.Fragment>
        <NavigationMenu.Item>
            <NavigationMenu.Trigger className="NavigationMenuTrigger">
                {parent.title}  <div className="CaretDown flex-center gap-2"> <Icon icon="chevron-down" size="0.4" /></div>
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="NavigationMenuContent">      
                <ul className="List two">
                    {
                        parent.title === "Products" &&
                            <div style={{ gridRow: 'span 4' }} className="category-list">
                                {parent.children.filter(itemsFilter).map((o, i) => {
                                    const childSlug = o.slug;
                                    if(o.children.length > 0){
                                        return <div  key={`child-${o.slug}-${i}`}  onMouseEnter={()=>{
                                            handleMouseEnter(o);
                                        }}>
                                            <CategoryItem isHovered={isHovered} title={o.title} href={childSlug} icon={o.icon} >
                                                {o.description}
                                            </CategoryItem>
                                        </div>;
                                    }
                                })}
                            </div>
                    }

                    {
                        parent.children.filter(itemsFilter).map((o) => {                    
                            return o.children.length > 0 && o.children.filter(itemsFilter).map((c, i) => {
                                const childSlug = c.slug;
                                if(isHovered === o.title){
                                    return <React.Fragment key={o.title + i}>
                                        {c.title === ("GoldRush Kit Templates") && <hr className="divider"/>}
                                        <ListItem key={`child-${c.slug}-${i}`} title={c.title} href={childSlug} icon={c.icon} shallow={o.title.includes("View")}>
                                            {c.description}
                                        </ListItem>
                                    </React.Fragment>;
                                }
                            });
                        })
                    }


                    {
                        parent.children.filter(itemsFilter).map((o, i) => {
                            const childSlug = o.slug;
                            if(o.children.length === 0){
                                return  <React.Fragment key={o.slug}>
                                    {(o.title === "Covalent （$CQT）代币经济学") && <hr className="divider-long"/>}
                                    <ListItem key={`child-${o.slug}-${i}`} title={o.title} href={childSlug} icon={o.icon}>
                                        {o.description}
                                    </ListItem></React.Fragment>;
                            }
                        })
                    }
                </ul>
            </NavigationMenu.Content>
        </NavigationMenu.Item>
        <style jsx>
            {`
            .category-list{
                border-right: 1px solid var(--black-medium);
                padding-right:15px;
                border-image: linear-gradient(0deg, var(--pink), var(--teal)) 30;
            }
            .divider {
                grid-column: span 2;
                margin-left: 0.75rem;
                width: calc(100% - 1.5rem);
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                border-color: var(--black-medium);
                border-image: linear-gradient(90deg, var(--pink), var(--teal)) 30;
            }
            .divider-long {
                grid-column: span 3;
                margin-left: 0.75rem;
                width: calc(100% - 1.5rem);
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                border-color: var(--black-medium);
                border-image: linear-gradient(90deg, var(--pink), var(--teal)) 30;
            }
            `}
        </style>
    </React.Fragment>;

};

const PlatformButton = ({ isLoggedIn }) => {

    let buttonText = "Signup for a free API key";
    let href = "/platform/auth/register/";
    if (isLoggedIn) {
        buttonText = "Launch GoldRush Platform";
        href = "/platform/";
    }

    return <React.Fragment>
        <div  className="ready">
            <Button href={href}>
                <div className="flex-center ">{buttonText}<Icon icon="chevron-right" color="white" size="0.6" /></div>
            </Button>
        </div>

        <style jsx>
            {`

            .ready{
                opacity: 1;
                transition: opacity 0.5s ease-in-out;
            }
            .platform:hover{
                background-color: #E2437B;
            }

        `}
        </style>
    </React.Fragment>;
};


export default function GlobalNav({ mobileMenuIsOpen, setMobileMenu, user, transparent = true }) {
    const theme = React.useContext(ThemeContext);
    
    const isLoggedIn = user.match({
        None: () => false,
        Some: () => true
    });

    const themeContext = useContext(ThemeContext);

    const [themeMode, setThemeMode] = useState("light");

    useEffect(() => {
        if (typeof window === 'undefined') return;
        setThemeMode(theme.theme);
    }, [theme.theme]);

    return (
        <React.Fragment>
            <NavigationMenu.Root className="NavigationMenuRoot">
                <Link href="/" className="NavigationMenuLogo flex-center" shallow>
                    <div className="video-bg">
                        {
                            themeContext.theme === "dark" ? <img height="32px" src="https://www.datocms-assets.com/86369/1720810310-goldrush-logo-full-dark-2x.svg" alt="GoldRush"/> : <img height="32px" src="https://www.datocms-assets.com/86369/1720810316-goldrush-logo-full-light-2x.svg" alt="GoldRush"/>
                        }
                    </div>
                    <div className="static-bg">
                        <img height="32px" src="https://www.datocms-assets.com/86369/1718899367-goldrush-logomark.svg" alt="GoldRush"/>
                    </div>
                </Link>
                <NavigationMenu.List className="NavigationMenuList">
                    {navigation.data.allNavigations.filter(itemsFilter).map((o, i) => {
                        return <ParentItem parent={o} key={i + o.title + o.slug} />;
                    })}
                    <li className="NavigationMenuTrigger ready non-primary"><Link href={"/pricing/"}>Pricing</Link></li>
                    {(!isLoggedIn && <li className="NavigationMenuTrigger ready"><Link href={isProduction ? "https://goldrush.dev/platform/" : "/platform/"}>Platform Login</Link></li>)}
                    <NavigationMenu.Indicator className="NavigationMenuIndicator">
                        <div className="Arrow" />
                    </NavigationMenu.Indicator>
                </NavigationMenu.List>

                <div className="NavigationPlatform">
                    <PlatformButton isLoggedIn={isLoggedIn} />
                </div> 

                <button style={{color: "var(--text)", alignItems: "center", display: "flex"}} onClick={theme.toggleTheme}>
                    <span className="material-symbols-rounded">
                        {themeMode === "dark" ? "light_mode" : "dark_mode"}
                    </span>
                </button>

                <div className="ViewportPosition">
                    <NavigationMenu.Viewport className="NavigationMenuViewport" />
                </div>
                {mobileMenuIsOpen &&
                    <div className="mobile-nav">
                        <div className="NavigationPlatform">
                            <PlatformButton isLoggedIn={isLoggedIn} />
                        </div>
                        {navigation.data.allNavigations.filter(itemsFilter).map((o, i) => {
                            return <div key={i}>
                                <label>{o.title}</label>
                                <ul className="List two mobile">
                                    {o.children.filter(itemsFilter).map((c, i) => {
                                        
                                        const childSlug = c.slug;
                                        if(c.title === "GoldRush API"){
                                            return c.children.filter((e: { hidden?: boolean }) => !e.hidden)
                                                .map((c) => {
                                                    return <ListItem shallow={false} key={`child-${c.slug}-${i}`} title={c.title} href={c.slug} icon={c.icon}>
                                                        {c.description}
                                                    </ListItem>;
                                                });
                                        }
                                        
                                        return <ListItem key={`child-${c.slug}-${i}`} title={c.title} href={childSlug} icon={c.icon}>
                                            {c.description}
                                        </ListItem>;
                                    })}
                                </ul>
                            </div>;
                        })}
                    </div>
                }

                <div className="mobile-toggle" onClick={() => {
                    setMobileMenu(!mobileMenuIsOpen);
                }}>
                    {mobileMenuIsOpen ? <span className="material-symbols-rounded">menu_open</span> : <span className="material-symbols-rounded">menu</span>}
                </div>
            </NavigationMenu.Root>

            <style jsx>
                {`
   
                  .logo-title{
                      margin-left:5px;
                  }
                  .video-bg{
                    display:block;
                }
            
                    .static-bg{
                        display:none;
                    }
                  .mobile{
                    margin-top:15px;
                  }
                  .mobile-nav{
                      height:calc(100vh - var(--main-nav-height));
                      top: var(--main-nav-height);
                      padding-top:1rem;
                      padding-left: 20px;
                      width:100vw;
                      position:fixed;
                      background: var(--background);
                      display:flex;
                      flex-direction:column;
                      gap:20px;
                      overflow:auto;
                  }
                  .place-items-center{
                    place-items:center;
                    display:flex;
                  }

                  .ready{
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                    height: auto;
                    padding-top: 0;
                }

                  .mobile-nav ul{
                    padding:0;
                  }
                  :global(.mobile-nav li){
                    margin:0;
                  }
                  .mobile-children{
                    display:flex;
                    flex-direction:column;
                    gap:10px;
                  }
                  .mobile-toggle{
                    position:absolute;
                    right:20px;
                    top:18px;
                    cursor:pointer;
                    display:none;
                  }

                  .non-primary {
                    opacity: 0.7;
                  }

                  .non-primary:hover {
                    opacity: 1;
                  }
                  .NavigationPlatformMobile{
                    width:max-content;
                  }

                  :global(.NavigationMenuRoot) {
                        color: var(--text);
                        background: ${!transparent && "var(--covalent-dark-blue)"};
                        background: var(--background-glass);
                  };

                  :global(.nav-transparent .NavigationMenuRoot) {
                        color: var(--text);
                  }

                  :global(.NavigationMenuRoot:not(.nav-transparent .NavigationMenuRoot)) {
                        color: var(--text);
                  }

                  
                  @media screen and (max-width: 1260px) {
                    :global(.NavigationPlatform){
                        display: none;                        
                    }
                  }
                  @media screen and (max-width: 825px) {
                    :global(.NavigationMenuList){
                      display: none;
                    }
                    .mobile-toggle{
                        display: flex;
                        place-items: center;
                        margin-top: 0.5rem;
                        margin-right: 0.5rem;
                    }
                    :global(.NavigationPlatform){
                      right:50px;
                    }
                    .platform{
                        width:max-content;
                    }                                    
                  }

                  @media screen and (max-width: 767px) {
                    :global(.NavigationPlatform){
                        display: none;                        
                    }
                    .video-bg{
                        display:none;
                    }
                    .static-bg{
                        display:block;
                    }
                    .mobile-nav{
                      height:calc(100vh - var(--main-nav-height));
                      top: var(--main-nav-height);
                      padding-top: 1rem;
                      padding-left: 1rem;
                      padding-right: 1rem;
                      width:100vw;
                      position:fixed;
                      background: var(--background);
                      color: var(--text);
                      display:flex;
                      flex-direction:column;
                      gap:20px;
                      overflow:auto;
                  }
                  }
                `}
            </style>

        </React.Fragment>
    );
}

