import React, { useContext } from "react";
import footer from "../data/navigation.json";
import { NewsLetter } from "./NewsLetter";
import Link from "next/link";

import * as Accordion from '@radix-ui/react-accordion';
import { ThemeContext } from "./ContextProviders/ThemeContext";

const AccordionFooter = ({ footer }) => (
    <>
        <Accordion.Root className="AccordionRoot" type="single" collapsible>
            {footer.data.allNavigations.filter(e => !e.hidden).map((parent, key) => {
                return <Accordion.Item className="AccordionItem" value={`item-${key}`} key={key + parent.title}>
                    <AccordionTrigger><h3 className="highlight">{parent.title}</h3></AccordionTrigger>
                    <AccordionContent>
                        <div className="accordion-children ">

                            {parent.children.filter(e => !e.hidden).map((child, nn) => {
                                if(child.title === "GoldRush API"){
                                    return child.children.filter((e: { hidden?: boolean }) => !e.hidden)
                                        .map((c, n) => {
                                            return <div key={c.title + n}>
                                                <Link className="underline-animation" href={c.slug}>{c.title}</Link>
                                            </div>;
                                        });
                                }
                                return <div key={child.title + nn}>
                                    <Link className="underline-animation" href={child.slug}>{child.title}</Link>
                                </div>;
                            })}

                        </div>
                    </AccordionContent>
                </Accordion.Item>;
            })}
        </Accordion.Root>
        <style jsx>
            {`  
            h3, h2{
              margin:0!important;
            }
            .highlight{
              color:var(--text);
            }
            .accordion-children{
              padding-top:1.5rem;
              padding-left:20px;
              display:flex;
              flex-direction:column;
              gap:1rem;
            }
          `}
        </style>
    </>

);

const AccordionTrigger = ({ children }) => (
    <Accordion.Header className="AccordionHeader">
        <Accordion.Trigger className="AccordionTrigger">
            {children}
            <span className="small-icon material-symbols-rounded AccordionChevron">expand_more</span>
        </Accordion.Trigger>
    </Accordion.Header>
);

const AccordionContent = ({ children }) => (
    <Accordion.Content className="AccordionContent">
        <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
);


export default function Footer({ handleToast }) {
    const themeContext = useContext(ThemeContext);
    return (
        <>
            <footer className="">
                <div className="footer-wrapper">
                    <div className="footer-content">
                        {handleToast && <NewsLetter handleToast={handleToast} />}
                        <div className="accordion-footer">
                            <AccordionFooter footer={footer} />
                        </div>
                        <div className="link-container">
                            <div className="footer-category animate-in">
                                <h2 className="highlight">Products &amp; Solutions</h2>
                                {footer.data.allNavigations
                                    .filter(e => !e.hidden)
                                    .filter((e) => (e.title === "Products" || e.title === "Solutions"))
                                    .map((p: any) => {
                                        return p.children.filter(e => !e.hidden).map((child) => {
                                            if(child.title === "API"){
                                                return child.children.filter((e: { hidden?: boolean }) => !e.hidden)
                                                    .map((c) => {
                                                        return <div key={c.title}>
                                                            <Link className="underline-animation animate-in" href={c.slug}>{c.title}</Link>
                                                        </div>;
                                                    });
                                            }
                                            return <div key={child.title}>
                                                <Link className="underline-animation animate-in" href={child.slug}>{child.title}</Link>
                                            </div>;
                                        });
                                    })}
                            </div>
                            {footer.data.allNavigations.filter(e => !e.hidden)
                                .filter((e) => (e.title !== "Products" && e.title !== "Solutions"))
                                .map((parent: any) => {
                                    return <div className="footer-category animate-in" key={parent.title}>
                                        <h2 className="highlight">{parent.title}</h2>
                                        {parent.children.filter((e: any) => !e.hidden).map((child) => {
                                            return <div key={child.title}>
                                                <Link className="underline-animation animate-in" href={child.slug}>{child.title}</Link>
                                            </div>;
                                        })}
                                    </div>;
                                })}
                        </div>
                    </div>
                </div>
                <div className="footer-divider" />
                <div className="copyright">
                    <div className="copyright-content">
                        <div className="footer-logo">
                            <Link href="https://www.covalenthq.com/" className="flex-center">
                                {themeContext.theme === "dark" ? <img height="42" src="https://www.datocms-assets.com/86369/1718900032-powered-by-covalent-dark.svg" alt="" /> : <img height="42" src="https://www.datocms-assets.com/86369/1718900043-powered-by-covalent-light.svg" alt="" />}
                            </Link>
                        </div>
                        <div className="flex right">
                            <Link className="highlight" href="/privacy-policy/">Privacy Policy</Link> <div>|</div>
                            <Link className="highlight" href="/cookie-policy/">Cookie Policy</Link> <div>|</div>
                            <Link className="highlight" href="/terms-of-use/">Terms Of Use</Link> <div>|</div>
                            <a className="highlight" href="#" id="open_preferences_center">Update Cookies Preferences</a>
                            <div>|</div>
                            <div className="highlight">&copy; 2024 Covalent</div>
                        </div>
                        <div className="soc2">
                            <a href="mailto:operations@covalenthq.com">
                                <img src="https://www.datocms-assets.com/86369/1707773164-soc2.png" alt="soc2" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
            <style jsx>
                {`  
                  .accordion-footer{
                    display:none;
                  }
                  .footer-logo {
                    margin-right: 2rem;
                  }
                  .right {
                    gap:1rem;
                    font-size:var(--font-size-2);
                    padding: 1rem 0;
                    width: calc(66% + 2.5rem);
                    justify-content: space-between;
                  }
                  .right a {
                    display: flex;
                    text-align: center;
                  }
                  .right div{
                    font-size:var(--font-size-2);
                    display: flex;
                    place-items: center;
                    color: var(--black-light);
                  }
                  .right div:last-of-type {
                    color: var(--text);
                  }
                  .highlight{
                    color:var(--text);
                  }
                  .highlight:hover {
                    opacity: 0.7;
                  }
                  .footer-category h2{
                    margin-top:0rem;
                  }
                  p{
                    padding:0;
                  }
                    .logo-title {
                    color: var(--text);
                    margin-left: 0.35rem;
                    font-size: 1.25rem;
                  }
                  footer{
                    color:var(--text);
                  }
                  .soc2 {
                    width: 80px;
                    margin-left: 2rem;
                  }
                  a{
                    text-decoration:none;
                    cursor: pointer;
                  }
                  .footer-wrapper{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    height: max-content;
                    background:var(--background);
                  }
                  .footer-content{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: max-content;
                    padding: var(--landing-page-padding);
                    max-width: var(--landing-page-max-width);
                    margin: 0 auto;
                    width: 100%;
                  }
                  .link-container{
                    display:flex;
                    justify-content:start;
                    gap: 4rem;
                    margin-top:2rem;
                    
                  }
                  .footer-category{
                    display:flex;
                    flex-direction: column;
                    gap: 1rem 4rem;
                    max-height: 300px;
                    flex-wrap: wrap;
                  }
                  .copyright{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    height: max-content;
                    padding-bottom:0rem;
                    background:var(--background);
                    box-shadow: 0 30px 40px rgba(0,0,0,.1);
                  }
                  .footer-divider{
                    border-top: 1px solid var(--code-border);
                    border-left:300px solid transparent;
                    border-right:300px solid transparent;
                    background: var(--background);
                  }
                  .copyright-content{
                    display: flex;
                    justify-content: space-between;
                    align-items:center;
                    padding: 1rem var(--landing-page-padding);
                    max-width: var(--landing-page-max-width);
                    margin: 0 auto;
                    width: 100%;

                  }
                  .mobile-footer-logo {
                    display: none;
                  }
                  @media screen and (max-width: 900px) {
                    .link-container{
                      flex-direction:column;
                      display:none;
                    }
                    .mobile-footer-logo {
                      display: flex;
                      width: 100%;
                      justify-content: center;
                    }
                    .mobile-footer-logo img {
                      width: 90px;
                    }
                    .footer-divider{
                      display:none;
                    }
                    .accordion-footer{
                      display:block;
                      margin-top: 2rem;
                    }
                    .footer-content{
                      padding:2rem 0;
                    }
                    .logo-title{
                      display:none;
                    }
                    .right{
                      gap:5px;
                      flex-wrap: wrap;
                      width: 100%;
                      place-self: center;
                      margin-left: 0;
                      justify-content: center;
                    }
                    .copyright-content {
                      padding:2rem 0;
                      flex-wrap: wrap-reverse;
                      gap: 1rem;
                      padding-bottom: 8rem;
                      z-index: 9;
                      background: none;
                    }
                    .footer-logo {
                      display: none;
                    }
                    .copyright {
                      background-image: linear-gradient(135deg,#00d8d521 0%,#ff4c8b33 100%);
                      position: relative;
                    }

                    .copyright:after {
                      content: "";
                      position: absolute;
                      left: 0;
                      top: 0;
                      right: 0;
                      height: 8rem;
                      background-image: linear-gradient(180deg, var(--background) 0%,transparent 100%);
                    }
                }
                @media screen and (max-width: 768px) {
                  .soc2{
                    display: none;
                  }
                }
                `}
            </style>
        </>
    );
}
